import './App.css';
import React from 'react';

export default class App extends React.Component {
  componentDidMount() {
    document.title = "11111"
  }

  tap = () => {

    // 上述代码中，我们使用了Three.js库创建了一个简单的烟雾场景。首先我们创建了一个场景、相机和渲染器，然后创建了一个烟雾材质和烟雾粒子，最后添加了一个烟雾光源和渲染循环
  }

  render() {
    return (<div className='App-header'>

      <div onClick={this.tap}>dsadsadsad</div>
      <div onClick={this.tap}>dsadsadsad</div>
      <div onClick={this.tap}>dsadsadsad</div>
      <div onClick={this.tap}>dsadsadsad</div>
      <div class="smoke"></div>
    </div>);
  }


}



